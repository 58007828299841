import React, { useEffect } from 'react';
import { useState } from 'react';
import { PieChart, Pie, Legend, Sector, Cell, ResponsiveContainer } from 'recharts';
import CategoryDropdownPosition from './CategoryDropdownPosition';




const PhonemeChart = ({phoneme, data, count, count_inside, count_start, count_end, data_inside, data_start, data_end, sample, sample_start, sample_inside, sample_end})=> {
        console.log(sample, sample_end, sample_start, sample_inside, "sample")
        const [position, setPosition] = useState()
        const [outdata, setOutdata] = useState(data)
        const [positionCount, setPositionCount] = useState(count)
        const [outsample, setSample] = useState(sample)
        let positions = ["All positions"]
        if (data_start){
                positions.push('start')
        } 
        if (data_inside){
                positions.push('inside')
        } 
        if (data_end){
                positions.push('end')
        } 


        const COLORS = ['#00C49F', '#FFBB28', '#FF8042'];
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
         
                return (
                    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                        {`${(percent * 100).toFixed(0)}%`}
                    </text>
                );
            };

        useEffect(()=>{
                if (position === 'start'){
                        setOutdata(data_start)
                        setPositionCount(count_start)
                        setSample(sample_start)
                }
                else if (position =="end"){
                        setOutdata(data_end)
                        setPositionCount(count_end)
                        setSample(sample_end)
                }
                else if (position =="inside"){
                        setOutdata(data_inside)
                        setPositionCount(count_inside)
                        setSample(sample_inside)
                }
                else{
                        setOutdata(data)
                        setPositionCount(count)
                        setSample(sample)
                }

                
        },[position])
        return(
           
                <div className='border-2 p-5 bg-gray-100 rounded-xl'>
                <div className='flex flex-col'>
                        <div className='flex flex-row mx-auto items-center'><p className='max-auto text-3xl text-blue-600 font-bold'>{phoneme}</p> <CategoryDropdownPosition selectHandler={e => setPosition(e.target.value)} elValue={position} positions={positions}/>  </div>  
                        <p>Total count: {positionCount}</p>
                        <div className='flex flex-row gap-x-5 items-center mx-auto border-2 bg-white p-1 rounded-xl '><p className='text-green-500 text-md'>Sample: </p> <p className='text-2xl text-blue-600 font-bold'>{outsample}</p></div>
                        
                 </div>

                
                <PieChart width={400} height={400}>
                    <Legend layout="vertical" verticalAlign="top" align="top" />
                    <Pie
                        data={outdata}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={110}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {outdata.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
              
                </div>
        
        
        )
            

        
       
    }

export default PhonemeChart;
