import {useState, useEffect, useRef} from 'react'
import useAuth from '../../Users/hooks/useAuth';
import AccessInterface from '../../Users/AccessInterface';
import UserProfile from '../../Users/UserProfile';
import BookCase from '../../Books/BookCase';
import Audiobook from './Audiobook';
import Videobook from './Videobook';
import Collection from './Collection';
import { Link } from "react-router-dom";



const Library = () => {
    const { auth, setAuth } = useAuth();
    const [adminPage, setAdmin] = useState(false);
    const [creatorPage, setCreator] = useState(false);
    const [userProfile, setProfile] = useState(false);
    const [message, setMessage] = useState(null)
    const [subjects, setSubjects]=useState([])
    const [subject, setSubject] = useState()
    const [languages, setLanguages] = useState()
    const [error, setError] = useState()
    
    const itemsRef = useRef(null);



    const getSubjects = async () => {
        const token = localStorage.getItem("token");
            
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/subjects/list-books`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        /* body: JSON.stringify(outdata),*/
        }).catch(error => {
            setError("Problems with server, check later")
          });;
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (!response.ok) {
            setError("server problem, check later")
        
            };
            const json = await response.json();
            
            setSubjects(json)
    }

    const getLanguages = async () => {
        const token = localStorage.getItem("token");
            
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/subjects/list-languages`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        /* body: JSON.stringify(outdata),*/
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setLanguages(json)
        
            };
    }
    

    useEffect(()=>{getSubjects(); getLanguages()},[])


    function scrollToId(itemId) {
        
        const frames = [ 
            { transform: "translateX(2em)"},
            { transform: "translateX(-2em)"},
            { transform: "translateX(0)"},
      
        ]    
        const map = getMap();
        const node = map.get(itemId);
        
        if (node) {
        
        node.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: 'nearest'
        });

        node.animate(  
            frames ,{ duration: 1500 , fill: "forwards", iterations: 1 } 
              );
            }
       
        }
  
    function getMap() {
      if (!itemsRef.current) {
        // Initialize the Map on first usage.
        itemsRef.current = new Map();
      }
      return itemsRef.current;
    }

 



    

 


 

   




  

 
    const logout = () =>{
        localStorage.removeItem("token");
        setAuth({});
    }

   

    if (userProfile) {
        return <UserProfile successHandler={(state) => setProfile(state)} />
    }

    if (adminPage) {
        return <AccessInterface successHandler={(state) => setAdmin(state)} />
    }

    if (creatorPage) {
        return <BookCase successHandler={(state) => setCreator(state)} />
    }

    return (
        
        
        error ?    <div className='text-3xl font-bold mx-auto p-20'>{error}</div> 
                :
                subject? 
                (subject.type === "Audiobook") ? <Audiobook subject={subject} exerciseHandler={(state)=>{setSubject(state)}}/> : <Videobook subject={subject} exerciseHandler={(state)=>{setSubject(state)}}/>
                
              :  <div className="flex flex-col h-screen overflow-hidden">
                         
                                                                         
                            <header className="w-full grid md:grid-cols-2 grid-cols-1 content-stretch font-mono sm:mx-auto mt-2  p-2 shadow-md ">
                               
                                {/* login user block */}   
                                    <div>
                                    <Link to="/" onClick={()=>{setSubject()}}><p className='ml-2 text-xl text-center md:text-left md:text-3xl font-mono font-semibold text-black-800'>The Catchpraser</p></Link>
                                    </div>
                                    <div className='flex flex-row place-content-start md:place-content-end' >
                                       
                                            
                                            {auth?.role === "ADMIN" &&
                                                
                                                    
                                                        <button className="text-xl  text-indigo-500 hover:text-indigo-800  p-2" onClick={() => setAdmin(true)}>Admin</button>
                                                    
                                            }

                                            {["CREATOR", "ADMIN"].includes(auth?.role) &&
                                                <button className="text-xl text-indigo-500  hover:text-indigo-800  p-2" onClick={() => setCreator(true)}>Creator</button>
                                            }

                                          
                                    
                                    
                                        
                                       
                                            
                                            

                                        

                                        
                                            {auth?.username &&
                                                <button   className='text-teal-600 hover:text-teal-800  p-2 text-xl' 
                                                            onClick={() => 
                                                                    {
                                                                        if (auth.username !== 'guest')
                                                                            {setProfile(true)}
                                                                        else
                                                                            {setMessage('Please register!')
                                                                                setTimeout(() => {setMessage(null)},1000)
                                                                            }
                                                                    }
                                                                    
                                                                    }>
                                                    {message ? <p className='text-red-500 font-bold text-xl'>{message}</p> : auth?.username}
                                                </button>
                                                }
                                                                                        
                                                                                        {auth?.username && (
                                                
                                                <button   className="text-xl text-teal-600 hover:text-teal-800 p-2"
                                                            onClick={logout}
                                                >
                                                    Logout 
                                                </button>
                                                
                                            )}
                                        
                                        

                                    
                                    </div>
                                
                            

                            </header>
                           
                            
                            <div className='flex-1 overflow-y-scroll'>
                           
                             
                                <div className='mt-5 flex flex-row  '>

                                    <div className='lg:basis-1/6 w-0 border-r-2 invisible lg:visible static  '>
                                    
                                    <div className='fixed p-2 flex flex-col  '>
                                        <p className='text-2xl font-semibold font-mono border-gray-400  p-2 text-black-600'>Languages </p>
                                        {languages &&<div className='px-2 text-left pt-5'>
                                            
                                            {languages.map(
                                                    (category,i) => 

                                                        {   
                                                    
                                                        let cat = category.split('_')[0]
                                                    
                                                        let key_value = 'index'+category
                                                            return (
                                                                <div    className='py-3'
                                                                        key={key_value}
                                                                >
                                                                <button className='hover:text-blue-400 text-xl text-center font-medium'
                                                                        onClick={() => {scrollToId(category)}}
                                                                >
                                                                {cat} 
                                                                </button>
                                                                </div>

                                                            )
                                                            }
                                            )
                                            }
                                        </div>}
                                    </div>
                            
                                    </div>
                                    
                                    <div className=' lg:basis-5/6 w-full flex flex-col '>
                                    <div className=' flex flex-col'> 
                                        {languages &&
                                        languages.map((item, i)=>{

                                        return (
                                            <div key={i+item} 
                                                className='flex flex-col ml-5'
                                                ref={(node) => {
                                                    const map = getMap();
                                                    if (node) {
                                                    map.set(item, node);
                                                    } else {
                                                    map.delete(item);
                                                    }
                                                }}
                                            
                                            >
                                            <p className='text-2xl font-mono'>{item} collection</p>
                                            <div className=' border-b-2 border-gray-400'>
                                                <Collection key={item.userid+item.title+item.author+i} language={item} itemHandler={(state) => setSubject(state)}/>
                                            </div>
                                            </div>
                                        )


                                        }) }
                                    
                                    
                                    </div>
                                    </div>    

                                </div>
                                
                            </div>

                            
                           
                        </div>
    )
}

export default Library;