import {useState, useEffect, useRef} from 'react'
import useAuth from '../../Users/hooks/useAuth';
import AccessInterface from '../../Users/AccessInterface';
import UserProfile from '../../Users/UserProfile';
import BookCase from '../../Books/BookCase';
import Card from './Card';
import Audiobook from './Audiobook';
import Videobook from './Videobook';




const Author = ({language,author, itemHandler}) => {
    console.log(language,author, "author")
    const [subjects, setSubjects]=useState([])
    const [subject, setSubject] = useState()
   
    
    const itemsRef = useRef(null);



    const getSubjects = async (language, author) => {
        const token = localStorage.getItem("token");
            
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/subjects/list-books?language=${language}&author=${author}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        /* body: JSON.stringify(outdata),*/
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setSubjects(json)
        
            };
    }

  

    useEffect(()=>{getSubjects(language,author);},[])


        

 



    

 


 

   




  

 
 

    return (
        
   
                        
                        <div className="flex flex-row md:flex-col">
                            
                                                                         
                                <div className='flex flex-col'>
                                    <div className='border-b-4 border-dobbel border-gray-400 text-center font-mono font-semibold text-xl'>{author}</div>
                                    <div className='flex flex-col xl:flex-row gap-5 mx-auto p-10 '>
                                       {subjects &&
                                       subjects.map((item, i)=>{

                                        return (

                                            <Card key={item.userid+item.title+item.author+i} course={item} courseHandler={(state) => itemHandler(state)}/>
                                        )


                                       }) }
                                    </div> 
                                </div>               
                                </div>
                         
    )
}

export default Author;