import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Paginator from './Paginator';
import { MdMicNone, MdMic } from "react-icons/md";
import axios from 'axios';
import useAuth from '../../Users/hooks/useAuth';

const SnapsVideo = ({subject, file})=>{
    console.log(subject, file, "inside snaps")
    const mimeType = "audio/webm";
    const [page, setPage] = useState(1)
    const [segment, setSegment] = useState()
    const [audioSnap, setAudioSnap] = useState()
    const [isgranted, setIsGranted] = useState(false)
    const [stream, setStream] = useState()
    const [audio, setAudio] = useState(null);
    const [snapPath, setSnapPath] =useState(null)
    const [buttonState, setButtonState] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [speechToText, setSpeechToText]= useState()
    const [assessment, setAssessment] = useState({});
    const { auth, setAuth } = useAuth();


    const mediaRecorder = useRef(null);
    const [recordingStatus, setRecordingStatus] = useState("inactive");
    const [audioChunks, setAudioChunks] = useState([]);
    const inputRef_0 = useRef(null);
    const recordRef = useRef(null);
    const pulseRef = useRef(null);

    const [duration, setDuration] = useState()

    let audio_record = new Audio(audio)
    const snap = new Audio(snapPath);

   

    const sendBlob = ({blob, subject, file, segment}) => {
        console.log("subjectha", subject)
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_USER_API_URL}/analysis/upload_blob/${subject.owner}/${subject.language}/${subject.author}/${subject.title}/${file.file}/${segment.start}/${segment.end}/${subject.type}`
        const formData = new FormData();
        
        formData.append('uploaded_file', blob);
        formData.append('sendText',JSON.stringify({reference: segment.text}))
    
       
        const config = {
            headers: {
            'content-type': blob.type, 
            Authorization: `Bearer ${token}`
            },
            
        };
        axios.post(url, formData, config).then((response) => {
            
            setAssessment(response.data)
            
            })
            .catch((error) => {
                console.error("Error uploading file: ", error);
            });
    }  
   

    const changeDuration = (e) => {
        const seconds = Math.floor(e.target.duration);
        setDuration(seconds);
    }
    const pulse_frames = [ 
        { transform: "scale(1.2)"},
        { transform: "scale(1.0)"},
        ]
        
      
        const progress_frames = [ 
        { transform: "scale(1.05)", color: "inherit" },
        { transform: "translateX(224px)", color: "inherit" }, 
       
        
        { transform: "scale(1.0)" , color: "inherit"},
        ]
      
        const playback_frames = [ { color: "green" },
        { transform: "scale(1.05)", color: "green" },
        { transform: "translateX(224px)", color: "green" }, 
       
        { transform: "scale(1.2)", color: "green" },
        { transform: "scale(1.0)" , color: "inherit"},
        ]

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setIsGranted(true);
                setStream(streamData)
                
                
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };
   


    snap.addEventListener("durationchange", changeDuration);


    useEffect(() => {
        if (navigator) {
        navigator.permissions
            .query({ name: "microphone" })
            .then(async function (result) {
            if (result.state === "granted") {

                //If granted then you can directly call your function here
            
                if ("MediaRecorder" in window) {
                    try {
                        const streamData = await navigator.mediaDevices.getUserMedia({
                            audio: true,
                            video: false,
                        });
                        setIsGranted(true);
                        setStream(streamData)
                        
                        
                    } catch (err) {
                        alert(err.message);
                    }
                } else {
                    alert("The MediaRecorder API is not supported in your browser.");
                }
            } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                console.log(prompt)
               
            } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
            }
            });
        } else {
        console.log("navigator is not supported by this browser.");
        }
    }, []);


    useEffect(()=>{setPage(1); setAssessment({})},[file, subject])


    const getSegment = async ({subject, file, page}) => {
        console.log(subject, "getsegment")
        const token = localStorage.getItem("token");
        const outdata={"author":subject.author, 'title': subject.title, 'language':subject.language, 'page':page, 'file': file, "type": subject.type}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/get-segment/${page}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setSegment(json)
            
            
            
            
        
            };
    }

    const getAudioSnap = async ({subject, file, segment}) => {
        console.log(subject, file, "getaudiosnap")
        const token = localStorage.getItem("token");
        const outdata={"author":subject.author, "type": subject.type, "file":file, 'start': segment.start, 'end': segment.end, 'title': subject.title, 'language':subject.language,'owner': subject.owner}     
   
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/audio/get-published-snap`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setAudioSnap(json)
           
        
            };
    }

    function findArrayElementByKey(array, key) {
        return array.find((element) => {
          return element[key]=== key;
        })
      }
    
    useEffect(()=>{
        setSnapPath(`${process.env.REACT_APP_USER_API_URL}`+audioSnap)
        
        snap.load()

       

    }, [audioSnap, page])
   
    useEffect(()=>{ getSegment( {"subject": subject, "file":file.file,"page":page} )
                },[file, subject, page])
  

    useEffect(()=>
            {   if (segment){
                    if (segment.hasOwnProperty('start') || segment.hasOwnProperty('end')){
                getAudioSnap({"subject": subject, "file" : file.file, "segment" : segment})}
            }
                
            },[segment, subject, file, page])





    const startRecording = async () => {

        setRecordingStatus("recording");
        setButtonState(true)
        //create new Media recorder instance using the stream
        const media = new MediaRecorder(stream, { type: mimeType });
        //set the MediaRecorder instance to the mediaRecorder ref
        mediaRecorder.current = media;
        //invokes the start method to start the recording process
        
        mediaRecorder.current.start();
        let localAudioChunks = [];
        
        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
        };
    const stopRecording = () => {
        if (recordRef.current){
        recordRef.current.getAnimations().forEach(function (arrayItem) {
            arrayItem.pause()
        });}
      
        setRecordingStatus("inactive");
        setButtonState(false)
        //stops the recording instance
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
        //creates a blob file from the audiochunks data
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        //creates a playable URL from the blob file.
        const audioUrl = URL.createObjectURL(audioBlob);
        sendBlob({"blob": audioBlob, "subject":subject, "file": file, "segment":segment})
        setAudio(audioUrl);
        console.log(audioUrl, audioChunks, audioBlob)
        setAudioChunks([]);
    };
    };

    const Play_record = () => {
  

    
        setTimeout(()=> {
            
          audio_record.currentTime=0;
          
          audio_record.play()
          
        }  , 300);
        
        
      };

    useEffect(() => {
        
        let audio_duration = 1
        if (duration) {
            audio_duration = duration
        }
        
        
        
    
        if (recordingStatus === 'recording') {
            
               
                
            
          const timer = setTimeout(() => {
            stopRecording()
          }, 3*audio_duration*1000)

          if (recordRef.current){
            recordRef.current.animate(  
              progress_frames,{ duration: 3*audio_duration*1000 , fill: "forwards", iterations: 1 } 
            );
          }
          if (pulseRef.current) {
            pulseRef.current.animate(  
              pulse_frames,{ duration: 3*audio_duration*1000 , fill: "forwards", iterations: 100 } 
            );
          }
          return () => clearTimeout(timer);
        }
        
      }, [recordingStatus])
    
        
    return (

        segment && 
        <div className="w-full pt-5">
             <div className='mx-auto'>
            
            <Paginator maxpage={file.maxpage} 
                                        page={page}
                                        clickHandler={
                                            (page) => {snap.pause(); setPage(page);setAudio(null); setButtonState(true); setAssessment({})}
                                        } 
            />

        </div>
            <div className='flex flex-col border-2 rounded-xl border-blue-200 md:p-10 mx-auto mt-5 '>    
                {
                (segment.hasOwnProperty('text')) && 
                <div className='flex flex-col'>
                    {console.log(snapPath, "snappath")}
                    <p className='text-xl mx-auto text-balance'>{segment.text}</p>
                
                    {audioSnap && 
                    <video src={snapPath} type="video/mp4" autoPlay muted controls/>
                    } 
                </div>
                }

            </div>

            <>
        { isgranted ?
        
        <div className="flex flex-col mt-5 mx-auto py-3 border-2 border-dotted border-blue-400 rounded-md">

          <div className='flex flex-row mx-auto'>  
          <div>
            {recordingStatus === 'inactive' ? <MdMicNone size='32px'/> : <div ref={pulseRef}><MdMic size='32px' color="blue" /></div>}
          </div>
          <div className="w-64 h-9 border-2 rounded-full border-blue-200 scale-75">
            <button className=" rounded-full w-8 h-8 py-1 bg-blue-400" ref={recordRef} ></button>
          </div>
          </div>

          <div className="flex flex-row  gap-x-5   m-auto py-3">
           {  recordingStatus === 'inactive' ? <button className="bg-green-100 hover:bg-green-200 rounded-xl p-2" 
                    onClick={()=>{startRecording()}}
                    
                    
            >
                Record
            </button>
            :  <button className="bg-red-100 hover:bg-red-200 rounded-xl p-2" 
            onClick={()=>{stopRecording()}}
            
            
    >
        Stop
    </button>
     
            
        }
        <button disabled={buttonState}  className={`m-auto px-3 py-2 rounded-xl     ${
            buttonState === true
            ? " text-gray-500 border-gray-300"
            : "bg-blue-100  border-blue-300 text-black-600  hover:bg-blue-200"
        }`}
        onClick={()=>{Play_record()}}>
          
            <p className="text-center">Compare</p>
            
          </button>
        </div>

        { (auth.username === 'guest') && 
        <div className='text-red-500 text-xl font-semibold mx-auto'>AI assessment is available only to registered and approved users</div>}
        { assessment &&
        <div className='mx-auto mt-5'>
            {console.log(assessment, "assessment")}
            {(assessment.hasOwnProperty("user")) && 
                <div className='flex flex-col'>
                    
                <div className='flex  mx-auto  flex-col items-center'>
                <p className='text-md text-green-400 font-mono'> AI assessment of your speech:</p>
                
                
                
                </div>
                

                <div className='px-10 gap-x-5 p-2 flex flex-col  md:flex-row  border-2 border-gray-200 dotted-line  my-5'>
                <div>
                
                
                
                { (assessment.user.hasOwnProperty('NBest'))  && 
                <div className='md:basis-2/3 flex flex-wrap gap-x-1 items-center'>
                    
                    {assessment.user['NBest'][0]['Words'].map((word, i)=>{
                        return(
                        (word.PronunciationAssessment.ErrorType !== 'Omission') ? 
                            <div className={
                            `flex flex-col gap-x-2 border-2 rounded-xl ${(word['PronunciationAssessment']['AccuracyScore'] >= 80) ? 
                            "bg-green-200" : (word['PronunciationAssessment']['AccuracyScore'] >= 40) ? 
                            "bg-yellow-200" : "bg-red-200" } `}>
                                <p className='text-xl text-center'>{word['Word']}</p> 

                            {(word.hasOwnProperty("Phonemes")) && 
                                 <div className='flex flex-row'>{word["Phonemes"].map(
                                    (ph, i)=>{
                                        return(
                                            <div className={`h-4 w-4 border-2 rounded-xl ${
                                            (ph.PronunciationAssessment.AccuracyScore>=80) ? "bg-green-500" : (ph.PronunciationAssessment.AccuracyScore>=40) ? "bg-yellow-500" : "bg-red-500" }`}></div>
                                        )
                                    }
                                )   
                                    }
                            </div>}
                        <p className='text-sm text-center text-semibold text-blue-600'>{word['PronunciationAssessment']['AccuracyScore']}</p> 
                        </div>
                        :
                        <div className='w-2 h-2 bg-gray-600 rounded-xl'></div>
                        )
                        })}
                    
                        </div>
                    }
                
                </div>
                
                { (assessment.user.hasOwnProperty('NBest'))  && 
                <div className='md:basis-1/3 px-5 rounded-xl flex flex-col border-dotted bg-blue-50 border-2 border-blue-300'>
                    
                    { Object.keys(assessment.user['NBest'][0]['PronunciationAssessment']).map((item, i)=>{
                        
                        return(<div>{item} :   {assessment.user['NBest'][0]['PronunciationAssessment'][item]}</div>)
                    }) }

                </div>}
                </div>
                </div>}


            {(assessment.hasOwnProperty("narrator")) &&
             
            
            <div className='flex flex-col'>
                {console.log('narrator', assessment, assessment.hasOwnProperty("narrator"))}
            <div className='flex  mx-auto  flex-col items-center'>
            <p className='text-md text-green-400 font-mono'> AI assessment of the narrator's speech:</p>

            
            </div>
            

            <div className='px-10 gap-x-5 p-2 flex flex-cols-2 border-2 border-gray-200 dotted-line  my-5'>
            <div>
            
            
            
            { (assessment.narrator.hasOwnProperty('NBest'))  && <div className='flex flex-wrap gap-x-1'>
            
                {assessment.narrator['NBest'][0]['Words'].map((word, i)=>{
                    return(<div className='flex flex-col'><p className='text-xl'>{word['Word']}</p> 
                    <p className='text-sm text-center text-blue-600'>{word['PronunciationAssessment']['AccuracyScore']}</p> </div>)})}
                
                </div>}
            
            </div>
            
            { (assessment.narrator.hasOwnProperty('NBest'))  && 
            
            
            <div>
                
                { Object.keys(assessment.narrator['NBest'][0]['PronunciationAssessment']).map((item, i)=>{
                
                    return(<div>{item} :   {assessment.narrator['NBest'][0]['PronunciationAssessment'][item]}</div>)
                }) }

            </div>}
            </div>
            </div>
            }
        </div>
        }

            
            
         </div>      
        :
        <div className="flex flex-col m-auto">        <p>Enable your micropohone for this exercise</p>
        <button className="border-gray-300 border-2 mx-auto px-1" onClick={getMicrophonePermission} type="button">
        Get Microphone
         </button>
         </div>

        }
        </>
            

           


        </div>

    )


}
export default SnapsVideo;