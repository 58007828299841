import Selector from "./Components/Selector";
import useAuth from "../Users/hooks/useAuth";
import { useEffect, useState } from "react";
import UploadFile from "./Components/UploadFile";
import BookTitle from "./Components/BookTitle";
import DropdownLang from "./Components/DropdownLang";
import DropdownType from "./Components/DropdownType";



const BookCase = ({successHandler}) => {
    const {auth, setAuth} = useAuth();
    const [lang, setLang] = useState("Define the language")
    const [bookcase, setBookcase] = useState([]);
    const [newTag, setTag] = useState("");
    const [newAuthor, setAuthor] = useState("");
    const [changeList, setChangeList] = useState([]);
    const [changedTags, setChangedTags] = useState([]);
    const [currentBook, setBook] = useState("");
    const [pages, setPages] = useState(0);
    const tabs = ['Books management'];
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [newBookcaseError, setNewBookcaseError] = useState("");
    const [type, setType] = useState("Book or movie?")
   



    const fetchData = async (route, method) => {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_USER_API_URL}/bookcase/${route}` 
        const response = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
            return null;
        }
        if (response.ok) {
            const json = await response.json();
            return json;
        }
        return null
    }

    const createBookcase = async ({author, title, language, type}) => {

        await fetchData(`create-bookcase/${author}/${title}/${language}/${type}`, "POST");
    }

    const getBookcase = async () => {
        const json = await fetchData("get-bookcase", "GET");
        if (json) {
            setBookcase(json);
        }
    }

    const deleteBookcase = async ({author, title, language, type}) => {
        await fetchData(`delete-bookcase/${author}/${title}/${language}/${type}`, "DELETE");
    }

   

 



    const getPages = async () => {
        const json = await fetchData(`pages/${currentBook}`, "GET");
        if (json) {
            setPages(json.pages);
        }
    }








    const commit = async () => {
        for (const change of changeList) {
            const splits = change.action.split(" ");
            switch (splits[0]) {
                case "DELETE":
                    
                    await deleteBookcase({author:change.author, title: change.title, language:change.language, type:change.type });   
                    
                    break;
                case "CREATE":
                    await createBookcase({author:change.author, title: change.title, language:change.language, type: change.type });
                    break;
                
                
                
                
            }
        }
        setChangeList([]);
        setChangedTags([]);
        getBookcase();
        
    }

    const removeChange = (i) => {
        let array = [...changeList];
        array.splice(i, 1);
        setChangeList(array);
    }

    const addChange = (change) => {
        setChangeList([...changeList, change]);
    }

    const addCreateBookcase = (event) => {
        console.log(bookcase, "okc")
        event.preventDefault();
        setNewBookcaseError("");
        if (bookcase.includes({"author": newAuthor,'tag': newTag, 'language': lang, "type": type }) ) 
        {
            setNewBookcaseError("Book with this name already exists.");
        } 
        else if (newTag === "") 
        {
            setNewBookcaseError("Name for book required");
        
        } 
        else if (newAuthor === "")
        {  setNewBookcaseError('The name of the author is required')

        } 
        else if (lang === 'Define the language') 
        {
            setNewBookcaseError("The language of the book required");
        } 
        else if (type === 'Book or movie?') 
            {
                setNewBookcaseError("What is this, a book or movie?");
            } 
        else 
        {
            addChange({
                author: newAuthor,
                title: newTag,

                action: `CREATE`,
                language : lang,
                type : type
            });
        }
       
    }

    const addDeleteBookcase = ({author, title, language, type}) => {
        addChange({
            author: author,
            title: title,
            type : type,
            action: "DELETE",
            language: language

        });
    }

   




    useEffect(() => {getBookcase()}, []);
   

    
    return (
        <div className="flex flex-col p-10 mx-auto">
            {console.log(bookcase, "bookcase")}
            <button className="border-0"
                onClick={()=> {successHandler(false)}}      
            ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>
           
                
                   
            
            
            
                <div className="mx-auto border-4 border-double border-gray-400 mt-5">
                    { currentBook ?  
                        <BookTitle book_title={currentBook} successHandler={(state) => setBook(state)} />
                    : 
                        <div className="flex flex-col p-10">
                            <div className="items-center flex flex-col">
                            <p className="font-semibold text-xl text-blue-400">Add a book/movie</p>
                                <form className="flex flex-col border-2 border-gray-200 shadow mx-auto p-5 rounded-md ">
                                    <div className="flex flex-row gap-4 mt-2 items-center">
                                        <div>
                                            <p>Author</p>
                                            <div className="mx-auto border-2 border-gray-200">
                                                <input className="p-2" type="text" placeholder="Author" value={newAuthor} onChange={e => setAuthor(e.target.value)} />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Title</p>
                                            <div className="mx-auto border-2 border-gray-200">
                                                <input className="p-2" type="text" placeholder="Title" value={newTag} onChange={e => setTag(e.target.value)} />
                                            </div>
                                        </div>
                                        <div>
                                            <p>Language</p>
                                            <DropdownLang selectHandler={(event) => {
                                                setLang(event.target.value);
                                                }}
                                            elValue={lang}/>
                                        </div>  
                                        <div>
                                            <p>Audiobook or movie?</p>
                                            <DropdownType selectHandler={(event) => {
                                                setType(event.target.value);
                                                }}
                                            elValue={type}/>
                                        </div> 
                                    </div>

                                    
                                    {newBookcaseError !== "" && newBookcaseError}
                                    {/* Button has to be in the form to prevent getting kicked off the page */}
                                    <button className="p-2 mx-auto mt-4 rounded-md border-2 border-gray-400 hover:bg-green-100 bg-gray-100 text-blue-500" onClick={addCreateBookcase}>Commit</button>

                                </form>

                            {(changeList?.length>0)&&
                                <div className="flex flex-col border-2 border-gray-200 bg-gray-50  p-5 my-5">
                                    {changeList.map((change, i) => {
                                        return (
                                        <div className="grid grid-cols-5 gap-2 mx-auto">
                                            <div className="text-gray-600 text-xl p-2 text-center">{change.author}</div>
                                            <div className="text-gray-600 text-xl p-2 text-center">{change.title}</div>
                                            <div className="text-gray-600 text-xl p-2 text-center">{change.language}</div>    
                                            <div className="text-gray-600 text-xl p-2 text-center">{change.type}</div>                                        
                                            <div className="tex committ-md text-green-400 p-2">{change.action}</div>
                                            <button className="border-2 border-brown-500 bg-brown-100 text-red-400 font-semibold p-2" onClick={() => {removeChange(i)}}>Remove</button>
                                        </div>
                                        )
                                    })}
                                    <button className="border-2 bg-blue-100 hover:bg-blue-200 mx-auto text-xl p-2 rounded-xl" onClick={commit}>Commit</button>
                                </div>
                            }
                            </div>
                            <div className="py-10 my-10">
                            {
                            
                        
                                <div className="grid  grid-cols-4 items-center border-2 border-gray-200 shadow-xl mx-auto my-2 p-5 gap-2">

                                    {bookcase.map((book, i) => {
                                        console.log("book", book)
                                        return (
                                            <> 
                                                
                                                        <div className="border-2 border-gray-200 p-2" type="text"> {book.author}</div>
                                                        <div className="border-2 border-gray-200 p-2" type="text"> {book.tag}</div>

                    
                                                <button className="border-2 border-teal-500 bg-teal-50 rounded-md" 
                                                        onClick={() => {
                                                            if (book.hasOwnProperty("type")){
                                                                setBook({author:book.author, title: book.tag, language: book.language,  type : book.type})
                                                            }
                                                            else {setBook({author:book.author, title: book.tag, language: book.language, type : null})

                                                            }

                                                        }
                                                    }
                                                >
                                                    Info
                                                </button>
                                            
                                                <button className="border-2 border-red-500 bg-red-100 text-sm font-semibold text-red-400" 
                                                        onClick={() => {
                                                            if (book.hasOwnProperty("type")){

                                                                addDeleteBookcase({language: book.language, author: book.author, title:book.tag, type:book.type})

                                                            }
                                                            else{
                                                                addDeleteBookcase({language: book.language, author: book.author, title:book.tag, type: null})
                                                            }
                                                        
                                                        }
                                                        }
                                                >
                                                    Delete
                                                </button>
                                                
                                            
                                            </>
                                        )
                                    })}
                                </div>
                            }
                            </div>
                        
                        </div>
                    }
                </div>
            
            
        </div>
    )
    }

export default BookCase;