import axios from 'axios';
import useAuth from '../../Users/hooks/useAuth';
import { useState } from "react";

const UploadFile = ({book, successHandler}) => {
  
    const [file, setFile] = useState()
    const [uploadProgress, setUploadProgress] = useState(0);
   

 
    function handleChange(event) {
        setFile(event.target.files[0])
      }

    function handleSubmit(event) {
        event.preventDefault()
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_USER_API_URL}/upload/upload_file/${book.language}/${book.author}/${book.title}/${book.type}`
        const formData = new FormData();
        formData.append('uploaded_file', file);
    
        const config = {
            headers: {
            'content-type': file.type,
            Authorization: `Bearer ${token}`
            },
            onUploadProgress: function(progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
              }
        };
        axios.post(url, formData, config).then((response) => {
            
            successHandler(true)
            
            })
            .catch((error) => {
                console.error("Error uploading file: ", error);
            });
    }  

    return(

        <div className='flex flex-col p-5 border-2'>
        <form className="flex flex-col mx-auto" onSubmit={handleSubmit}>

            <input className='mx-auto' type="file" onChange={handleChange}/>
            <button className='border-2 bg-gray-100 rounded-xl mx-auto p-2' disabled={file? false : true } type="submit">Upload</button>
            <div className='flex mx-auto p-5'>
            <progress value={uploadProgress} max="100"></progress>
            </div>
        </form>


        </div>
      


    )

}
export default UploadFile;