import React, { Component } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const CAPTCHA_CHARS = 6;

class Captcha extends Component {


    componentDidMount () {
        loadCaptchaEnginge(CAPTCHA_CHARS, "white", "black", "lower");
    }

    onSubmit = () => {
        let user_captcha = document.getElementById("user_captcha_input").value;
        if (validateCaptcha(user_captcha)) {
            this.props.func();
        } else {
            alert("Captcha mismatch");
            document.getElementById('user_captcha_input').value = "";
        }
    }

    render() {
        return (
            <div className="border-2 border-gray-300 mx-auto p-5">
                <div className="flex flex-col justify-center items-center">
                    <LoadCanvasTemplateNoReload />
                </div>
                <div className="flex flex-col justify-center items-center ">
                    <input
                        className="border-2 border-gray-300 p-2"
                        id="user_captcha_input"
                        type="text"
                        placeholder="Type what you see"
                    />
                </div>
                <div className="flex flex-col justify-center items-center">
                    <button className="text-white bg-gray-700 p-2 hover:bg-gray-800 rounded-xl mt-5" onClick={() => this.onSubmit()}>Submit</button>
                </div>
            </div>
        )
    }
}
export default Captcha;