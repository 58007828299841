import { useForm, useController } from "react-hook-form";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Select from 'react-select';
import Captcha from "./Components/Captcha";


const getCharacterValidationError = (string) => {
    return `Your password must have at least 1 ${string} character`;
  };
/*https://reacthustle.com/blog/yup-password-validation-with-formik*/ 
const schema = yup.object({
    username: yup.string().required("Please enter username"),
    email: yup.string().email("Please enter valid email").required("Please enter email"),
    password: yup.string()
        .required("Please enter a password")
        // check minimum characters
        .min(8, "Password must have at least 8 characters")
        // different error messages for different requirements
        .matches(/[0-9]/, getCharacterValidationError("digit"))
        .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
        confirmPassword: yup.string()
        .required("Please re-type your password")
        // use oneOf to match one of the values inside the array.
        // use "ref" to get the value of passwrod.
        .oneOf([yup.ref("password")], "Passwords do not match"),
    role: yup.string().nullable().required("Please select role"),
   
    gender: yup.string().nullable().optional(),
    age: yup.string().nullable().optional()
  });
   
const rolesList = [
  { value: 'USER', label: 'User' },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'CREATOR', label: 'Creator' }
];


const Register = ({successHandler}) => {
   
    const [apiError, setApiError] = useState();
    const [captchaVerifed, setCaptcha] = useState(false);
    const { setAuth } = useAuth();

    const [emailError, setEmailError] = useState("");

    const verifyCaptcha = () => { setCaptcha(true); }

    let navigate = useNavigate();
    const {
      register,
      handleSubmit,
      formState: { errors }, control
    } = useForm({
        resolver: yupResolver(schema)
      });

    const { field: { value: langValue, onChange: langOnChange, ...restLangField } } = useController({ name: 'role', control });
    

    const getUserData = async (token) => {
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          let userData = await response.json();
          console.log(userData);
          userData["token"] = token;
          setAuth(userData);
          localStorage.setItem("token", token);
          setApiError(null);
          /*navigate("/soundgroups", { replace: true });*/
          if (successHandler) {
            successHandler(false);
          } else {
            navigate("/");
          }
        }
      };
   
    const onFormSubmit = async (data) => {
        console.log(data, "data")
        setEmailError("");
        if (!isNaN(data["age"])) {
          data["age"] = Number(data["age"]);
        }
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const response_login = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/login`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({email: data.email, password: data.password}),
              });
            console.log((response_login, "response_login"))
            const token = await response_login.json();
           
            await getUserData(token["token"]);
        } else if (response.status === 422) {    
          setEmailError("Not valid email");
        } else {
          let errorResponse = await response.json();
          setApiError(errorResponse["detail"]);
          setAuth(null);
        }
    };
    
    const onErrors = (errors) => console.error(errors);
    
    

    return (
      <div className="md:w-1/2 flex flex-col border-2 border-gray-200 border-dotted mt-10 ">
        <button className=""
                onClick={()=> {successHandler(false)}}
              
                
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
        {!captchaVerifed &&
          <Captcha func={verifyCaptcha}/>
        }
        {captchaVerifed &&
        <div className="flex flex-col">
        <h2 className="text-xl text-primary text-center font-bold my-2">
          Registration 
        </h2>

        <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
          
          <div className="flex flex-col justify-center items-center gap-2 ">
            
            
          <input
              type="text"
              placeholder="Username"
              className="border-2 border-gray-300 rounded-md mx-auto text-xl p-2"
              name="username"
              autoComplete="off"
              {...register("username")}
          />
          {errors?.username && errors.username.message}
            
          <input
              type="email"
              placeholder="email@email.com"
              className="border-2 border-gray-300 rounded-md mx-auto text-xl p-2 "
              name="email"
              autoComplete="off"
              {...register("email")}
          />
          {errors?.email && errors.email.message}
          {emailError !== "" && emailError}

          <input
              type="password"
              autoComplete="off"
              placeholder="Select password"
              className="border-2 border-gray-300 rounded-md mx-auto text-xl p-2"
              name="password"
              {...register("password")}
          />
            {errors?.password && errors.password.message}

            <input
              type="password"
              autoComplete="off"
              placeholder="Repeat password"
              className="border-2 border-gray-300 rounded-md mx-auto text-xl p-2"
              name="confirmPassword"
              {...register("confirmPassword")}
            />
            {errors?.confirmPassword && errors.confirmPassword.message}

            <Select
              type="text"
              placeholder="Select role"
              className="border-2 border-gray-300 rounded-md mx-auto text-xl p-2 "
              isClearable={true}
              name="role"
              options={rolesList}
              value={langValue ? rolesList.find(x => x.value === langValue) : langValue}
              onChange={option => langOnChange(option ? option.value : option)}
              {...restLangField}
            />
            {errors?.role && errors.role.message}

         





      

            <button className="border-2 border-gray-300 rounded-md mx-auto text-xl p-2 bg-blue-700 hover:bg-blue-800 text-white ">
              Submit
            </button>
            <div className="flex flex-wrap">
          <p className=" flex flex-wrap text-xs text-blue-400">By clicking Submit you agree with out <Link className="text-xs font-bold hover:text-blue-700" target="_blank" rel="noopener noreferrer"  to="/policy" >Privacy Policy</Link>, if you do not agree - proceed as a guest</p>.
        </div>
          </div>
        </form>
       

        {apiError && (
          <div className="alert alert-error shadow-lg">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>{apiError}</span>
            </div>
          </div>
        )}
        </div>
        }
      </div>
    );
};

export default Register;