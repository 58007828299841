import Library from "../components/Library";
import useAuth from "../../Users/hooks/useAuth";
import Login from "../../Users/Login";
import ForgotPassword from "../../Users/ForgotPw";
import Register from "../../Users/Register";
import { useEffect, useState } from "react";



const Entry = ()=>{
    const { auth, setAuth } = useAuth();
    const [forgotPass, setForgotPass] = useState(false)
    const [register, setRegister] = useState(false)


    const checkToken = async () => {
        const token = localStorage.getItem("token");
        if (token && !auth?.email) {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
            if (response.ok) {
                let userData = await response.json();
                userData["token"] = token;
                setAuth(userData);
            }
        }
    }

   useEffect(() => {checkToken()});

    if (auth?.email) {
        return (
            <Library />)
        
    }

    return(
    
        

        (!auth?.email) && 
        <div className="flex flex-col lg:flex-row gap-y-2 md:gap-y-10">
            <div className="flex flex-col px-10 py-10 gap-y-10 my-5  lg:w-2/3 "> 
                <div className="flex flex-col justify-center items-center ">
                <p className="text-xl md:text-5xl  text-black-600 font-mono font-medium">
                    The Catchphraser
                </p>
                <p className="text-sm ">the proof-of-concept project</p>
                </div>
                <div className="flex flex-col justify-center items-center ">
                
                <div className=" border-t-2 border-gray-300 p-2 w-full lg:w-2/3 ">  
                <p className="mb-5 text-md md:text-2xl text-center font-bold font-semibold text-black-400">The concept</p>          
                 <p className="text-md md:text-xl font-semibold font-mono text-black-400">We suggest using audio books as a suitable 
                    framework for improving of one's pronunciation. To assess the dynamics of learners pronunciation skills and gather statistics for developing of adaptive learning approach we use Microsoft pronunciation assessment ai service. Audio books are transcribed using Whisper_timestamped. </p>
                </div>
                </div>
            </div>
            {register   ?   
                            <Register successHandler={(state)=> {setRegister(state)}}/> 

                        :   
                            <div className=" lg:w-1/3 md:p-10 my-10 flex flex-col  gap-4 md:mx-10  space-y-10 justify-end ">

                             
                                 
                                
                        
                                   
                                {
                                forgotPass  ?   
                                                <ForgotPassword forgotPassHandler={(state) =>{setForgotPass(state)}} />
                                            : 
                                            
                                                
                                                <Login  forgotPassHandler={(state) =>{setForgotPass(state)}}
                                                        registerHandler={(state) =>{setRegister(state)}}/>
                                                
                                }
                            </div>  
                            
            }

        </div>
        
    
        
    )
}
export default Entry;
