import React from "react";

const CategoryDropdownRoles = ({ selectHandler, elValue}) => {
  const L2List =  ["USER", "ADMIN", "CREATOR"];
  
  return (
    
    <select value={elValue} 
    name="ROLE" 
    id="ROLE" 
    className="h-12 border-4 border-gray-400 rounded-md p-2 text-md" 
    onChange={selectHandler}
>


{L2List.map(
    (les,i) => 
        {
            return (
                <option value={les} key={les}>
                    {les}
                </option>
            );
        }
    )
}           
</select>
    
    
    
    
    
   
  );
};


export default CategoryDropdownRoles;
