import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import ForgotPassword from "./ForgotPw";

const Login = ({forgotPassHandler, registerHandler}) => {
    const [apiError, setApiError] = useState();
    const { setAuth } = useAuth();
    const [forgotPw, setForgot] = useState(false);
    const [error, setError] = useState()
    let navigate = useNavigate();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const getUserData = async (token) => {
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          let userData = await response.json();
          //console.log(userData);
          userData["token"] = token;
          setAuth(userData);
          setApiError(null);
          localStorage.setItem("token", token);
          /*navigate("/soundgroups", { replace: true });*/
        }
      };
    

    const onFormSubmit = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).catch(error => {
          setError("Problems with server, check later")
        });
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
          const token = await response.json();
          await getUserData(token["token"]);
        } else {
          const errorResponse = await response.json();
          const detail = errorResponse["detail"];
          // detail is a list if status code is 422, which happens when format of email is wrong
          if (typeof detail === "string") {
            setApiError(detail);
          } else {
            setApiError(detail[0]["ctx"]["reason"]);
          }
          setAuth({});
        }
      };

      const onErrors = (errors) => console.error(errors);
      
      if (forgotPw) {
        return <ForgotPassword successHandler={(state) => setForgot(state)} />
      }

      return (
        
          
          
          
          
         
        <div className="flex flex-col mx-auto p-5 lg:border-l-2 lg:border-t-2 border-gray-300 justify-center items-center  gap-y-2 lg:gap-y-10">
          {error && <div className="text-xl text-red-400 mx-auto">{error}</div>}
          <div className="border-2 border-gray-200 flex flex-col gap-y-2 p-2 bg-gray-50">
            <button onClick={()=>{setAuth({'email': 'guest', 'username': 'guest'})}} 
                        className="bg-gray-700 hover:bg-gray-800 text-white font-bold py-2 px-4 mx-auto rounded ">
                  Proceed as a guest
                </button>
                <p className="text-sm">no pronunciation assessment in the guest mode</p>
          </div>
                <p>or</p>
            <div className="border-2 border-gray-200 flex flex-col  p-2 mx-2">

              <form  onSubmit={handleSubmit(onFormSubmit, onErrors)}>
                <div className="flex flex-col justify-center items-center gap-2">
                <input
                  type="text"
                  placeholder="email@email.com"
                  className=" mr-2 ml-2 text-left py-2 px-4 text-lg  border-2 border-gray-400  text-black-500  rounded-md shadow-sm outline-none appearance-none"
                  name="email"
                  autoComplete="off"
                  {...register("email", { required: "The email or the username is required" })}
                />
                {errors?.email && errors.email.message}

 
                <input
                  type="password"
                  placeholder="your password"
                  className="mr-2 ml-2 text-left py-2 px-4 text-lg  border-2 border-gray-400  text-black-500  rounded-md 
                  shadow-sm outline-none appearance-none "
                  name="password"
                  {...register("password", { required: "The password is required" })}
                />
                {errors?.password && errors.password.message}
                <div className="flex md:flex-row flex-col md:space-x-2">
                  <button className="bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Log in
                  </button>
                  
                </div>           
                </div>
              </form>
            </div>

          <div className="flex flex-col justify-center items-center">
            <button className="text-sm text-blue-400" onClick={()=> {forgotPassHandler(true)}}>Forgot password?</button>
          </div>

          <div className="flex flex-col justify-center items-center">
            <button className="bg-green-500 hover:bg-green-700 my-4 text-white font-bold py-2 px-4 rounded" onClick={()=> {registerHandler(true)}}>Create new acount</button>
          </div>

          {apiError && (
            <div className="alert alert-error shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current flex-shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>{apiError}</span>
              </div>
            </div>
          )}
        </div>
      );
    };
    
    export default Login;