import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";



const UserWords = ({language, date}) => {
    const {auth, setAuth} = useAuth();
    const [stats, setStats] = useState();
   
    useEffect(()=>{ getMyStats()},[language,date])
    
    const snap = new Audio()
  
    const Play = (audio) =>{
    if (audio){
    snap.src = `${process.env.REACT_APP_USER_API_URL}/`+audio
    snap.load()
    snap.play()
              
    }       
    }   
        

    
    const getMyStats = async () => {
        const token = localStorage.getItem("token");
        
       
        if (date ==='All records')
            {            

            fetch(`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/words` , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                
                    setStats(json);}
                })
            }
        

        else if (date ==='Select date')
        {
            console.log("no date")
        }            
        else 
        {
            fetch(`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/words?date=${date}` , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                    
                    setStats(json);}
                })
        }



     
    }
    

    return (
    
       <>


       {(stats) ?
            <div className="grid grid-flow-row-dense grid-cols-2 mx-auto gap-y-5 gap-x-5 md:p-10">
               <p className="text-sm md:text-md text-blue-600 text-center">Word</p><p className="text-sm md:text-md text-blue-600 text-center">Sentence</p>
               
           { stats.map((word,i) => {
            console.log(word, "word")
               return(
                   <>
                
                       <div className={
                               `flex flex-col gap-x-2 border-2 rounded-xl ${(word["word"]['PronunciationAssessment']['AccuracyScore'] >= 80) ? 
                               "bg-green-200" : (word["word"]['PronunciationAssessment']['AccuracyScore'] >= 40) ? 
                               "bg-yellow-200" : "bg-red-200" } `}>
                                   <p className='text-md md:text-3xl text-center'>{word["word"]["Word"]}</p> 
   
                               {(word["word"].hasOwnProperty("Phonemes")) && 
                                    <div className='mx-auto flex flex-row'>{word["word"]["Phonemes"].map(
                                       (ph, i)=>{
                                           return(
                                               <div className={`h-4 w-4 border-2 rounded-xl ${
                                               (ph.PronunciationAssessment.AccuracyScore>=80) ? "bg-green-500" : (ph.PronunciationAssessment.AccuracyScore>=40) ? "bg-yellow-700" : "bg-red-500" }`}></div>
                                           )
                                       }
                                   )   
                               }
                                   </div>
                               }
                               {(word.hasOwnProperty("Phonemes")) && 
                                    <div className='mx-auto flex flex-row gap-2'>{word["Phonemes"].map(
                                       (ph, i)=>{
                                           return(
                                               <div>{ph}</div>)                                           
                                       }
                                   )   
                               }
                                   </div>
                               }
                           <p className='text-sm md:text-xl text-center text-bold text-blue-600'>{word["word"]['PronunciationAssessment']['AccuracyScore']}</p> 
                       
                           <div className="mx-auto flex flex-row flex-wrap gap-0.5 ">{word["word"]["Phonemes"].map((ph, i)=>{
                               return(
                                   <div> {(ph["Phoneme"]!=="") && ph["Phoneme"]}({ph["PronunciationAssessment"]["AccuracyScore"]}) 
                                   </div>
                                       
   
                               )
                           })} 
                           </div>
                       
                       </div>
                       
                       
                       
   
                   
                  
                       <button className="border-2 rounded-xl hover:bg-blue-100 bg-blue-50" onClick={()=>{Play(word['audio'])}}>
                           
                       <div>
                           <p className="hover:text-blue-600 text-xl text-blue-400">{word["text"]}</p>
   
                       </div>
                       </button>
                   </>
               )
               })
           
           
               }
           
           </div>
         :  (date !== 'Select date') ? <div className="mx-auto text-xl text-blue-400 mt-10">loading...</div> : <div className="mx-auto text-xl text-blue-400 mt-10" >select record/records!</div>
        }
           
       
    
     
    
       
        </>
        
     
        
       )
        
        
    
}

export default UserWords;
