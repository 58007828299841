import React from "react";

const DropdownType = ({ selectHandler, elValue}) => {
  const L2List =  ["Book or movie?", "Audiobook", "Movie"];
  
  return (
    
    <select value={elValue} 
    name="type" 
    id="type" 
    className=" mx-auto border-2 border-gray-200 bg-white focus:border-gray-300 p-2" 
    onChange={selectHandler}
>


{L2List.map(
    (les,i) => 
        {
            return (
                <option value={les} key={les}>
                    {les}
                </option>
            );
        }
    )
}           
</select>
    
    
    
    
    
   
  );
};


export default DropdownType;
