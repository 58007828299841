import React from "react";

const CategoryDropdownScores = ({ selectHandler, elValue}) => {
  const  types =  ["AccuracyScore", "FluencyScore", "CompletenessScore", "PronScore"];
  
  return (
    
    <select value={elValue} 
    name="Type" 
    id="Type" 
    className="mr-2 ml-2  text-left py-2 px-4 text-sm md:text-lg bg-cyan-100  border-4  border-blue-400 text-black-500 bg-white border rounded-md 
                shadow-sm outline-none appearance-none focus:border-blue-600"
    onChange={selectHandler}
>


{types.map(
    (type,i) => 
        {
            return (
                <option value={type} key={type}>
                    {type}
                </option>
            );
        }
    )
}           
</select>
    
    
    
    
    
   
  );
};


export default CategoryDropdownScores;
