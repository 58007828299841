import React from 'react'
import { useState, useEffect } from 'react';
import Selector from './Selector';
import Snaps from './Snaps';
import SnapsVideo from './SnapsVideo';


const Videobook = ({subject, exerciseHandler})=>{
    console.log(subject, "videovidep")
    const [transcripts, setTranscripts] = useState([])
    const [tabs, setTabs]  = useState() 
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [currentScript, setCurrentScript] = useState()
    



    const getTranscripts = async (course) => {
        console.log("course", course)
        const token = localStorage.getItem("token");
        const outdata={"owner": course.owner, "author":course.author, 'title': course.title, 'language':course.language, 'type': course.type}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/subjects/get-structure`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setTranscripts(json)
            
            
            
            
        
            };
    }



    useEffect(()=>{

        if (transcripts){
            setCurrentScript(transcripts[activeTabIndex])}
        
    },[activeTabIndex, transcripts, tabs])
       
    useEffect(() => {getTranscripts(subject)},[subject]);

   
    useEffect(()=>{
        var files = []
        
        transcripts.forEach((x, i) => 
            {
                if ("file" in x) {
                    files.push(x.file)
                }

            })
        setTabs(files)
        console.log(tabs, "tabs")

    },[transcripts])

 

    
    
   


    




    
  
        return(
         tabs && 
        <div className='flex flex-col md:p-10 border-2 bg-gray-50 shadow-xl rounded-xl mx-auto mt-10 '>
            <button className="border-0"
                onClick={()=> {exerciseHandler(null)}}      
            ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
            </button>
            <div className='flex flex-col mb-5'>   Video  
                <p className='text-center font-mono p-5 text-xl text-black-600 font-bold'>{subject.title}</p>
                <p className='text-center font-mono text-md font-semibold text-black-400'>by {subject.author}</p>
            </div>
            <Selector 
                clickHandler={(tab_index) =>
                        {
                            setActiveTabIndex(tab_index);
                            
                        }
                } 
                tabsData={tabs} 
                active = {activeTabIndex}

                
            
            />

        
            { currentScript &&
            <div className='border-r-2 border-gray-200 md:p-10 border-l-2 border-b-2 bg-white'>
                <SnapsVideo subject={subject} file={currentScript}/>
            </div>
            }
            

       
        </div>
        );
    }

export default Videobook;
