import React from "react";
import Select from 'react-select'
const CategoryDropdownTypes = ({ selectHandler, elValue}) => {
  const  types =  [{'value': "Sentences", 'label': 'Sentences'},
    {'value': "Words", 'label': 'Words'} ,{'value': "Phonemes", 'label': 'Phonemes'}];
  
  return (
    
    <Select 
    onChange={selectHandler}
    options={types}
/>



    
    
    
    
    
   
  );
};


export default CategoryDropdownTypes;
