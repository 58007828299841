import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import CategoryDropdownScores from "./CategoryDropdownScores";



const UserSentences = ({language, date}) => {
    const {auth, setAuth} = useAuth();
    const [stats, setStats] = useState();
    const [scoreType, setScoreType] = useState('AccuracyScore')
   
    useEffect(()=>{ getMyStats()},[language,date, scoreType])
    
    const snap = new Audio()
  
    const Play = (audio) =>{
    if (audio){
    snap.src = `${process.env.REACT_APP_USER_API_URL}/`+audio
    snap.load()
    snap.play()
              
    }       
    }   
        

    
    const getMyStats = async () => {
        const token = localStorage.getItem("token");
        
       
        if (date ==='All records')
            {            

            fetch(`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/${scoreType}/sentences` , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                
                    setStats(json);}
                })
            }
        

        else if (date ==='Select date')
        {
            console.log("no date")
        }            
        else 
        {
            fetch(`${process.env.REACT_APP_USER_API_URL}/analysis/${language}/${scoreType}/sentences?date=${date}` , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    localStorage.removeItem("token");
                    setAuth({});
                }
            }).then(json => {
                if (json) {
                    
                    setStats(json);}
                })
        }



     
    }
    

    return (
    
       <>


       {(stats) ?
            


            
            <div className="grid grid-flow-row-dense grid-cols-2 mx-auto gap-y-5 gap-x-5 md:p-10">

            
                <div><p  className="mt-5 text-md text-blue-600 text-center">Sentence</p></div><div className="flex flex-col md:flex-row items-center"><p className="text-md text-blue-600 text-center">Score</p> <CategoryDropdownScores selectHandler={e => setScoreType(e.target.value)} elValue={scoreType}/> </div>
           
                { stats.map((sentence,i)=>
                
                    { console.log(sentence, "sentence")
                    return(
                        <>
                        <div className="border-2 rounded-xl bg-gray-100">
                            <button className="m-5 p-2 text-sm md:text-xl text-blue-500 text-center border-2 bg-gray-200 hover:scale-105"
                                    onClick={()=>{Play(sentence['audio'])}}
                                >
                                {sentence.assessment.DisplayText}</button>
                            { (sentence.assessment.hasOwnProperty('NBest'))  && 
                    <div className='flex flex-wrap gap-x-1 content-center	'>
                    
                    {sentence.assessment['NBest'][0]['Words'].map((word, i)=>{
                        return(
                        <div className={
                            `flex flex-col gap-x-2 border-2 rounded-xl ${(word['PronunciationAssessment']['AccuracyScore'] >= 80) ? 
                            "bg-green-200" : (word['PronunciationAssessment']['AccuracyScore'] >= 40) ? 
                            "bg-yellow-200" : "bg-red-200" } `}>
                                <p className='text-sm md:text-xl text-center'>{word['Word']}</p> 

                            {(word.hasOwnProperty("Phonemes")) && 
                                 <div className='flex flex-row'>{word["Phonemes"].map(
                                    (ph, i)=>{
                                        return(
                                            <div className={`h-4 w-4 border-2 rounded-xl ${
                                            (ph.PronunciationAssessment.AccuracyScore>=80) ? "bg-green-500" : (ph.PronunciationAssessment.AccuracyScore>=40) ? "bg-yellow-500" : "bg-red-500" }`}></div>
                                        )
                                    }
                                )   
                                    }
                            </div>}
                        <p className='text-sm text-center text-semibold text-blue-600'>{word['PronunciationAssessment']['AccuracyScore']}</p> </div>)})}
                    
                        </div>
                    }
                        
                        </div>
                        
                        <div className="flex flex-col border-2 rounded-xl hover:bg-blue-100 bg-blue-50 ">
                            <p className="hover:text-blue-600 text-sm md:text-xl text-blue-400">Accuracy: {sentence.AccuracyScore}</p>
                            <p className="hover:text-blue-600 text-sm md:text-xl text-blue-400">Fluency: {sentence.FluencyScore}</p>
                            <p className="hover:text-blue-600 text-sm md:text-xl text-blue-400">Completeness: {sentence.CompletenessScore}</p>
                            <p className="hover:text-blue-600 text-sm md:text-xl text-blue-400">Pronunciation: {sentence.PronScore}</p>
                        </div>
                        </>
                    )


                })}

        </div>
        : (date !== 'Select date') ? <div className="mx-auto text-xl text-blue-400 mt-10">loading...</div> : <div className="mx-auto text-xl text-blue-400 mt-10">select record/records!</div>
     
        }
           
       
    
     
    
       
        </>
        
     
        
       )
        
        
    
}

export default UserSentences;
