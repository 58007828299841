
import { useEffect, useState } from "react";
import useAuth from "../../Users/hooks/useAuth";
import UploadFile from "./UploadFile";
import ProcessFile from "./ProcessFile";
import DropdownLang from "./DropdownLang";
import DropdownModel from "./DropdownModel";

const BookTitle = ({book_title, successHandler}) => {
    var models =  ["tiny", "base", "small", "medium", "large", "turbo" ];
    if (book_title.language === "Norwegian") {
        models =  ["NbAiLab/nb-whisper-tiny", "NbAiLab/nb-whisper-base", "NbAiLab/nb-whisper-small", "NbAiLab/nb-whisper-medium", "NbAiLab/nb-whisper-large" ];}
    else if (book_title.language === "English"){
        models =  ["tiny.en", "base.en", "small.en", "medium.en", "large" ];
    }
    else if (book_title.language === "Portuguese"){
        models =  ["tiny", "base", "small", "medium", "pierreguillou/whisper-medium-portuguese", "large" ];
    }
    
    const [listFiles, setListFiles] = useState()
    const {auth, setAuth} = useAuth();
    const [updated, setUpdated] = useState(false)
    const [model, setModel]= useState(models[2])
    const [transcript, setTranscript] = useState()
    const [segment, setSegment] = useState()
    const [audioSnap, setAudioSnap] = useState()

    const fetchData = async (route, method) => {
        
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_USER_API_URL}/upload/${route}` 
        const response = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
            return null;
        }
        if (response.ok) {
            const json = await response.json();
            return json;
        }
        return null
    }


    const getFiles = async (title) => {
        console.log(title, "title")
        const json = await fetchData(`list_files/${title.language}/${title.author}/${title.title}/${title.type}`, "GET");
        if (json) {
            setListFiles(json);
            setUpdated(false)
            
        }
    }



    const getAudioSnap = async ({author,  file, title, language, start, end, type}) => {
        const token = localStorage.getItem("token");
        const outdata={"author":author, "file":file, 'start': start, 'end': end, 'title': title, 'language':language, "type": type}      
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/audio/get-snap`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            setAudioSnap(json)
           
        
            };
    }


    useEffect(() => {getFiles(book_title); }, [book_title, updated]);
    useEffect(()=>
    {
        let snap = new Audio(`${process.env.REACT_APP_USER_API_URL}`+audioSnap)
        snap.load()
        snap.play()  
    },[audioSnap]
    
    )
    return(

    
    <div className="flex flex-col border-2 shadow-xl rounded-xl p-10 m-auto gap-y-5">
        <div className="flex flex-row ">
        <button className="border-0"
                onClick={()=> {successHandler(null)}}      
            ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
        {console.log(book_title, "here current")}
        </div>
      
            <div className="bg-blue-100 border-2 shadow-md rounded-md mx-auto mb-5"><p className="text-center text-md font-semibold">{book_title.author} : {book_title.title}</p></div>
  
            <div className={`${transcript ? "flex flex-cols-2" : "flex flex-col"}`}>
            
                <div className="flex flex-col">
                <p className="text-center mx-auto text-xl font-semibold text-blue-400">Upload audios and generate transcriptions</p>
                <UploadFile book={book_title} successHandler={(state) => setUpdated(state)} />

                <div className="flex flex-col mx-auto border-2 p-10 gap-y-10 ">

                
                <div className="flex flex-col gap-y-5 border-2 shadow-xl p-5">
                

                    <div className="flex flex-col gap-5">   
                    <p className='text-md font-mono font-semibold text-blue-400'>
                        Define the model to 
                         the audio
                    </p>                       
                    <DropdownModel list={models} selectHandler={(event) => {
                                                setModel(event.target.value);
                                                }} elvalue = {model}
                    />
                    </div>
                </div> 

                <div className="bg-gray-50 border-2 shadow-xl mx-auto p-5"> 
                    <p className=" text-center font-semibold text-blue-400 text-xl">Audio files</p>
                    
                    <div className="flex flex-col gap-y-5 py-10  ">
                    {listFiles && listFiles['files'].map((file, i)=>{
                        return( <>
                                {console.log(book_title, "book_title")}
                                <ProcessFile book={book_title} file={file}  model_size={model} transcriptHandler={(state)=>{setTranscript(state)}} />               
                                </>     
                        )
                    })}
                        
                    </div>
                </div>
                </div>
                
            </div>
            <>
            {transcript && 
               
                <div className="flex flex-col gap-y-5 p-10 border-2 shadow-xl">
                    {console.log(transcript, "transcript info")}
                    <button className="border-0"
                onClick={()=> {setTranscript()}}      
            ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
                     {console.log(transcript["transcript"], "transcripts fan")}
                    <p className="text-xl text-blue-400 font-semibold">Transcript </p>
               
                    {transcript["transcript"].hasOwnProperty("segments")&& transcript["transcript"]["segments"].map((item, i)=>{
                        
                        return(
                        
                        <div className="flex flex-row">
                        <div className="border-2 bg-sky-50 shadow-md" key={i+item}>{item.text}</div>
                        <button className="border-2 bg-blue-100" onClick={()=>{getAudioSnap({"start": item.start, "end": item.end, 'author': transcript.author, 'language': transcript.language, 'title': transcript.title, "file": transcript.file, 'type': transcript.type})}}>play</button>
                        </div>
                    )

                    }
                    
                    )}
                
                </div>
                
            }
            </>
            </div>
    </div>
    )
}
export default BookTitle;