import { useState, useEffect } from "react";

const ProcessFile = ({book, file, model_size, transcriptHandler}) => {

    const [committed, setCommited] = useState(false)
    const [submitted, setSubmitted] = useState([])
    const [published, setPublished] = useState(false)
    const [change, setChange] = useState(false)
 
    const onCommit = async ({book,  file, model_size}) => {
        const token = localStorage.getItem("token");
        const outdata={"book":book, "file":file, "size_of_model": model_size}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/send`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            setCommited(true)
        
            };
    }


    const getTranscript = async ({author,  file, model_size, title, language, type}) => {
        const token = localStorage.getItem("token");
        const outdata={"author":author, "file":file, 'size_of_model': model_size, 'title': title, 'language':language, "type": type}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/get-transcript`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            transcriptHandler({'transcript': json, 'language': language, 'author': author, 'title':title, 'file': file, 'type': type})
        
            };
    }

    const resegmentateTranscript = async ({author,  file, model_size, title, language}) => {
        const token = localStorage.getItem("token");
        const outdata={"author":author, "file":file, 'size_of_model': model_size, 'title': title, 'language':language}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/resegmentate-transcript`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            setChange(json)
            console.log(json, "resegmentate")
            };
    }
    
    const publishTranscript = async ({author,  file, model_size, title, language}) => {
        const token = localStorage.getItem("token");
        const outdata={"author":author, "file":file, 'size_of_model': model_size, 'title': title, 'language':language}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/publish-transcript`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setPublished({'model_size': model_size, 'published': true})
        
            };
    }

    const deleteTranscript = async ({author,  file, model_size, title, language, type}) => {
        const token = localStorage.getItem("token");
        const outdata={"author":author, "file":file, 'size_of_model': model_size, 'title': title, 'language':language, 'type':type}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/delete-transcript`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            transcriptHandler()
            setChange(json)
            
        
            };
    }
    const retractTranscript = async ({author,  file, model_size, title, language}) => {
        const token = localStorage.getItem("token");
        const outdata={"author":author, "file":file, 'size_of_model': model_size, 'title': title, 'language':language}          
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/retract-transcript`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
        // if the login is successful - get the token and then get the remaining data from the /me route
        if (response.ok) {
            const json = await response.json();
            
            setPublished({'model_size': model_size, 'published': false})
        
            };
    }

    const onRefresh = async ({book}) => {
    
        const token = localStorage.getItem("token");
        const outdata={"author":book.author, "title": book.title, "language":book.language}
             
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/whisper/get-submitted-files`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(outdata),
        });
        
       
        if (response.ok) {
            const json = await response.json();
            
            return json
        
            }
        else
        {
            return []
        };
        
    }


    const getFiles = async () => 
     { const json = await onRefresh({"book": book})
        if (json) {setSubmitted(json); }}

  

    useEffect(() => {getFiles()},[committed, published, change]);


    
    
   

    return(

        <div className={`flex flex-col items-center p-5  gap-5 border-2 rounded-xl bg-gray-100 `}>
            

           
            <p className="text-md font-semibold text-gray-800">{file}</p>
            <button onClick={()=>onCommit({"book": book, "file":file, "model_size": model_size})} className='hover:scale-110 hover:text-xl-200 hover:font-semibold hover:bg-gray-200  border-2 bg-gray-100 rounded-xl mx-auto p-2' type="submit">Transcribe</button>
            
            
            
        
                <div className="flex flex-col">
                    <p className="text-xl font-semibold text-blue-300 mx-auto ">Model size</p>
                    <div className="grid grid-cols-5 gap-x-2">
                    {submitted.map((item, i) => {
                        return (
                        (item[0]===file) &&
                            <>
                            {
                                item[1].map((size,j) =>
                                {     
                                        return (

                                           <>
                                                <button className="rounded-xl border-2 bg-indigo-100 hover:scale-110 p-2"
                                                        onClick={()=>getTranscript({'model_size': size.model_size, 'file': file, 'author': book.author, 'title': book.title, 'language': book.language, "type": book.type})}
                                                        >
                                                            {size.model_size}
                                                </button>
                                                
                                                <button onClick={()=>publishTranscript({'model_size': size.model_size, 'file': file, 'author': book.author, 'title': book.title, 'language': book.language})}
                                                        className={`border-2 rounded-xl ${size.hasOwnProperty('published') ? size.published ? "hover:bg-green-300 bg-green-200" : "bg-gray-200 hover:bg-gray-300" : " hover:bg-gray-300 bg-gray-200"}`}
                                                >
                                                    Publish
                                                </button>

                                                <button className={`border-2 rounded-xl ${size.hasOwnProperty('published') ? size.published ? "bg-red-200" : "bg-gray-200" : "bg-gray-200"}`}
                                                        onClick={()=>retractTranscript({'model_size': size.model_size, 'file': file, 'author': book.author, 'title': book.title, 'language': book.language})} 
                                                >
                                                    Retract
                                                </button>
                                                <button className={`border-2 rounded-xl ${size.hasOwnProperty('published') ? size.published ? "bg-red-200" : "bg-gray-200" : "bg-gray-200"}`}
                                                        onClick={()=>resegmentateTranscript({'model_size': size.model_size, 'file': file, 'author': book.author, 'title': book.title, 'language': book.language})} 
                                                >
                                                    Resegmentate
                                                </button>

                                                <button onClick={()=>deleteTranscript({'model_size': size.model_size, 'file': file, 'author': book.author, 'title': book.title, 'language': book.language})}
                                                        className={`border-2 rounded-xl ${size.hasOwnProperty('published') ? size.published ? "hover:bg-green-300 bg-green-200" : "bg-gray-200 hover:bg-gray-300" : " hover:bg-gray-300 bg-gray-200"}`}
                                                >
                                                    Delete
                                                </button>
                                           </>
                                        )
                             
                                        }       )
                                    }            
                            </>       
                         
                         
                                )
                        }
                    
                                    
                    )}
                
                        </div>

                    
                </div>

             
                
        
            

        </div>
      


    )

}
export default ProcessFile;