import React from "react";

const DropdownModel = ({ list, selectHandler,elvalue}) => {

 
  return (
    
    <select  value={elvalue}
    name="l2" 
    id="l2" 
    className=" text-left py-2 px-4 text-lg   border-4  border-teal-400 text-black-500 bg-white border rounded-md 
                shadow-sm outline-none appearance-none focus:border-teal-600" 
    onChange={selectHandler}
>


{list.map(
    (les,i) => 
        {
            return (
                <option value={les} key={les}>
                    {les}
                </option>
            );
        }
    )
}           
</select>
    
    
    
    
    
   
  );
};


export default DropdownModel;
