import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import Selector from "./Components/Selector";
import CategoryDropdownRoles from "./CategoryDropdownRoles";
import UserSettings from "./UserSettings";
import UserStats from "./UserStats";



const UserProfile = ({successHandler}) => {
    const {auth, setAuth} = useAuth();
    const [section, setSection]=useState()
    const tabs = ['User stats and results', 'User settings']
    const [activeTabIndex, setActiveTabIndex] = useState(0);


    


    useEffect(()=>{
        setSection(tabs[activeTabIndex])


    }, [activeTabIndex])
    
 return(

    <div className="flex flex-col mx-auto md:p-10">
        <button className="btn btn-circle btn-outline"
                onClick={()=> {successHandler(false)}}      
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>
        <p className="text-2xl text-blue-700 font-bold text-center">User profile</p>
        <div className="mx-auto mt-5">
    
            <Selector 
                clickHandler={(tab_index) =>
                        {
                            setActiveTabIndex(tab_index);
                            
                        }
                } 
                tabsData={tabs} 
                active = {activeTabIndex}

                
            
            />

        {section ?
        <div className="border-r-2 border-l-2 border-b-2 shadow-md md:p-10">
         {(section === 'User stats and results') && 
         <div className="p-10">
            
            
            <UserStats/>
            </div>} 

         {(section === 'User settings') &&
            
          <UserSettings/>  
         }
         
         </div>
         :
         <div></div>
        }
        
        


    </div>
    </div>
 )
}

export default UserProfile;
