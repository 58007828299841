import React from 'react'




const Card = ({course, courseHandler})=>
    {console.log(course, "course")
        
        return(
 
        <button onClick={()=>{courseHandler(course)}} className='h-48 w-36 hover:bg-gray-200 hover:scale-105 flex flex-col items-start p-10 border-l-8 border-t-2 border-b-2 border-r-2 shadow-xl border-double border-gray-600 bg-sky-200  mx-auto'>
           
             <p className='h-full w-full  text-center overflow-truncate text-sm text-blue-600 font-bold'>{course.title}</p>
       
        </button>
        );
    }

export default Card;
