import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";

import CategoryDropdownRoles from "./CategoryDropdownRoles";

const UserSettings = () => {
    const {auth, setAuth} = useAuth();
    const [requestRole, setRequestRole] = useState("");
    const [newName, setNewName] = useState("");
    const [nameError, setNameError] = useState("");
 
  

    const requestAccess = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/request-access/${requestRole}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.status === 409) {
            localStorage.removeItem("token");
            setAuth({});
        }
    } 
    
    const updateName = async () => {
        setNameError("");
        const token = localStorage.getItem("token");
        const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/update-username/${newName}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) {
            const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/users/me`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                let userData = await response.json();
                userData["token"] = token;
                setAuth(userData);
            }
        } else {
            setNameError(`Username ${newName} is already taken`)
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            setAuth({});
        }
    }

    const deleteUser = async () => {
        const token = localStorage.getItem("token");
        await fetch(`${process.env.REACT_APP_USER_API_URL}/users/delete-user`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        localStorage.removeItem("token");
        setAuth({});
    }

  

 






   

    

    return (
        <div className="h-auto flex flex-col">
           
            <div className="flex flex-col border-2 border-gray-300 rounded-xl p-10 mx-auto my-10 bg-white">
            

                <p className="p-10 text-center text-xl text-blue-600 font-semibold ">User settings</p>
                <div className="flex flex-row  border-2 border-gray-100 shadow mx-auto gap-10 p-5">
                    <div className="grid grid-cols-2 gap-y-5 border border-4 border-double border-gray-600 mx-auto p-10 rounded-xl shadow-md bg-gray-50">
                        <div className=""><p className="text-blue-600 font-semibold text-sm md:text-md">Username:</p></div><div> <p className="text-sm md:text-xl text-center font-semibold text-gray-600">{auth?.username}</p></div>
                        <div className=""><p className="text-blue-600 font-semibold ext-sm md:text-md">Email address:</p></div><div><p className="text-sm md:text-xl font-semibold text-gray-600 truncate "> {auth?.email}</p></div>
                        <div className=""><p  className="text-blue-600 font-semibold ext-sm md:text-md">Role:</p></div><div><p className="text-sm md:text-xl text-center font-semibold text-gray-600">{auth?.role}</p></div>

                    </div>
                    
                </div>



                <div className="flex flex-col items-stretch lg:flex-row border-2 border-gray-200 rounded-xl gap-2 p-5 mx-auto mt-10 bg-gray-100 shadow-md">

                    <div className="flex flex-col gap-5 p-2 items-center border-2 p-2 border-gray-200 rounded-md bg-gray-50">
                        <p className="text-md font-semibold text-blue-600">Change username:</p>
                        <div className=" h-12 border-4 border-gray-400 rounded-md p-2 text-xl">
                            <input className="outline-none" placeholder="Entter new username"
                                 type="text" onChange={(e) => setNewName(e.target.value)} value={newName} /></div>        
                        
                        {nameError&& <div>{nameError}</div>}
                        <button 
                        className="border-2 border-gray-400  mx-auto p-2 rounded-md text-md font-semibold text-blue-600 hover:text-blue-800 hover:bg-blue-100 bg-gray-50" 
                        onClick={updateName}>
                            Update username
                            </button>
                    </div>
                   
                    <div className="flex flex-col items-center gap-5 p-2  border-2 border-gray-200 rounded-md bg-gray-50 ">
                            <p className="text-md font-semibold text-blue-600">Change role:</p>
                            <CategoryDropdownRoles selectHandler={e => setRequestRole(e.target.value)} elValue={auth?.role} />
                    
                            <button     className="border-2 border-gray-400  mx-auto p-2 rounded-md text-md font-semibold text-blue-600 hover:text-blue-800 hover:bg-blue-100 bg-gray-50"
                                        onClick={requestAccess}>Update role</button>
                    </div>

            
                    
                           
                </div>
            </div>
        </div>
    )
}

export default UserSettings;
