import React from "react";

const DropdownLang = ({ selectHandler, elValue}) => {
  const L2List =  ["Define the language","English", "Norwegian", "Spanish", "Portuguese", "Italian"];
  
  return (
    
    <select value={elValue} 
    name="language" 
    id="narratorlanguage" 
    className=" mx-auto border-2 border-gray-200 bg-white focus:border-gray-300 p-2" 
    onChange={selectHandler}
>


{L2List.map(
    (les,i) => 
        {
            return (
                <option value={les} key={les}>
                    {les}
                </option>
            );
        }
    )
}           
</select>
    
    
    
    
    
   
  );
};


export default DropdownLang;
