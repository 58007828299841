import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import CategoryDropdownLanguages from "./CategoryDropdownLanguages";
import CategoryDropdownDates from "./CategoryDropdownDates";
import CategoryDropdownTypes from "./CategoryDropdownTypes";
import UserSentences from "./UserSentences";
import UserWords from "./UserWords";
import UserPhonemes from "./UserPhonemes";

const UserStats = () => {
    const {auth, setAuth} = useAuth();
    const [language, setLanguage] = useState()
    const [date, setDate] = useState('Select date')
    const [type, setType] = useState()
  
    
    

    

    

    return (
    
       <div className="flex flex-col ">
        
    
        <div className="flex flex-wrap gap-2 mx-auto items-center">
            <div className="flex flex-col">  <h1>Language:</h1> 
           <CategoryDropdownLanguages selectHandler={e => setLanguage(e.value)}  />
            </div>
            <div className="flex flex-col"> <h1>Records:</h1>
          <CategoryDropdownDates language={language} selectHandler={e => setDate(e.value)}  />
            </div>
            <div className="flex flex-col">
            <h1>Category:</h1>
          <CategoryDropdownTypes selectHandler={e => setType(e.value)}/> 
          </div>
       </div>


        {(type === 'Words') && <UserWords language={language} date={date}/>}


        
       {(type === 'Sentences') && <UserSentences language={language} date={date}/>}

       {(type === 'Phonemes') && <UserPhonemes language={language} date={date}/>}


       
        
        
        </div>
    )
}

export default UserStats;
