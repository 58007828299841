import React from "react";
import useAuth from "./hooks/useAuth";
import { useState, useEffect } from "react";
import Select from 'react-select'

const CategoryDropdownDates = ({ selectHandler, language}) => {
  const [data, setData] = useState()
  const {auth, setAuth} = useAuth();  
  const getFiles = async () => {

    const fetchData = async (route, method) => {
     
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_USER_API_URL}/analysis/${route}` 
      const response = await fetch(url, {
          method: method,
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
          }
      });
      if (response.status === 401) {
          localStorage.removeItem("token");
          setAuth({});
          return null;
      }
      if (response.ok) {
          const json = await response.json();
          return json;
      }
      return null
  }

        
    const json = await fetchData(`${language}/list_dates`, "GET");
    if (json) {
        let dates = [{'value':'All records', 'label': 'All records'}]
        json.map((item,i)=>{
          dates.push({'value':item, 'label': item})
        })
        
        setData(dates);
        
        
    }
}

  useEffect(()=>{getFiles()},[language])
  
  if (!data) return ( 
    <div>
                 Loading list...
                
    </div>
              )

  

  return (
    data&&<Select 
      onChange={selectHandler}
      options={data}
    />
     
    
  );
};


export default CategoryDropdownDates;
